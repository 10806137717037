import { BottomSheetWithIntent } from "@clipboard-health/ui-components";
import { type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeploymentEnvironmentName, environmentConfig } from "@src/appV2/environment";
import { APP_V2_USER_EVENTS, isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { convertToGeoLocation } from "@src/appV2/Location/utils";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { CurrentShiftStage } from "@src/appV2/redesign/Shift/constants";
import { getShiftCurrentStage } from "@src/appV2/redesign/Shift/WorkerShift/getShiftCurrentStage";
import { formatTime } from "@src/appV2/redesign/utils/formatTime";
import type { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import type { WorkerShift } from "@src/appV2/Shifts/Shift/types";
import { isAfter, parseISO, startOfMinute, subMinutes } from "date-fns";
import { useHistory } from "react-router-dom";

import { getSentHomeRequestCreatePath } from "../../CreateSentHomeRequest/path";
import { ClockInBottomSheet } from "../../Timekeeping/TimekeepingActions/ClockInBottomSheet";
import { UpdateEtaBottomSheet } from "./UpdateEtaBottomSheet";

interface ClockInOptionsBottomSheetProps {
  shift: WorkerShift;
  shiftState: ShiftStateData;
  breakPolicyNoteId: string;
  requiresBreakPolicyAcknowledgement: boolean;
  modalState: UseModalState;
  onCancelShift: () => void;
}

export function ClockInOptionsBottomSheet(props: ClockInOptionsBottomSheetProps) {
  const {
    modalState,
    onCancelShift,
    shift,
    shiftState,
    breakPolicyNoteId,
    requiresBreakPolicyAcknowledgement,
  } = props;

  const history = useHistory();

  const currentDate = useCurrentDate(1000);

  const { showErrorToast } = useToast();

  const shiftStage = getShiftCurrentStage(shift);
  const { isEarlyClockInEnabled } = shiftState.metadata;
  const earlyClockInEnabledInMinutes = shiftState.metadata.earlyClockInEnabledInMinutes ?? 0;
  const shiftStartDate = parseISO(shift.start);

  const earliestClockInTime = isEarlyClockInEnabled
    ? subMinutes(shiftStartDate, earlyClockInEnabledInMinutes)
    : shiftStartDate;

  const formattedClockInTime = formatTime(earliestClockInTime, shift.facility.tmz);
  const isAfterEarliestClockInTime = isAfter(startOfMinute(currentDate), earliestClockInTime);

  function getBottomSheetIntentContent() {
    if (isAfterEarliestClockInTime || shiftStage !== CurrentShiftStage.NOT_STARTED) {
      return undefined;
    }

    return `Clock in enabled at ${formattedClockInTime}`;
  }

  const updateEtaModalState = useModalState();
  const clockInModalState = useModalState();

  const isClockInForbidden =
    !isCapacitorPlatform() &&
    environmentConfig.REACT_APP_ENVIRONMENT_NAME === DeploymentEnvironmentName.PRODUCTION;

  return (
    <>
      <BottomSheetWithIntent
        modalState={modalState}
        intent="warning"
        intentContent={getBottomSheetIntentContent()}
        intentIcon="triangle-exclamation"
        footer={
          <DialogFooter
            orientation="vertical"
            onClose={() => {
              modalState.closeModal();
            }}
          />
        }
      >
        <Stack
          sx={{
            paddingX: 4,
          }}
        >
          <Stack spacing={4}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={!isAfterEarliestClockInTime}
              onClick={() => {
                if (isClockInForbidden) {
                  showErrorToast("Clock-in/out is only available on the mobile app.");
                  return;
                }

                logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOCK_IN_NOW_TAPPED, {
                  shiftId: shift._id,
                });
                clockInModalState.openModal();
              }}
            >
              Clock in now
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_LATE_FOR_SHIFT_TAPPED, {
                  shiftId: shift._id,
                });
                updateEtaModalState.openModal();
                modalState.closeModal();
              }}
            >
              I&apos;m late for the shift
            </Button>
            {isAfterEarliestClockInTime && (
              <Button
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_SENT_HOME_TAPPED, {
                    shiftId: shift._id,
                  });

                  history.push(getSentHomeRequestCreatePath(shift._id));
                  modalState.closeModal();
                }}
              >
                I was sent home
              </Button>
            )}
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CANCEL_SHIFT_TAPPED, {
                  shiftId: shift._id,
                });
                onCancelShift();
                modalState.closeModal();
              }}
            >
              Cancel my shift
            </Button>
          </Stack>
        </Stack>
      </BottomSheetWithIntent>
      <UpdateEtaBottomSheet
        modalState={updateEtaModalState}
        shiftStartDate={shiftStartDate}
        currentDate={currentDate}
        shiftId={shift._id}
        shiftName={shift.name}
        workplaceId={shift.facilityId}
      />
      <ClockInBottomSheet
        breakPolicyNoteId={breakPolicyNoteId}
        modalState={clockInModalState}
        shiftId={shift._id}
        workerId={shift.agentId}
        workplaceId={shift.facilityId}
        workplaceName={shift.facility.name}
        workplaceLocation={convertToGeoLocation(shift.facility.geoLocation.coordinates)}
        isNfcClockInEnabled={shift.nfcTag ?? false}
        nfcTagRequests={shift.nfcTagRequests ?? []}
        requiresBreakPolicyAcknowledgement={requiresBreakPolicyAcknowledgement}
      />
    </>
  );
}
