import { formatDate } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";

import { ShiftDetailsInnerCard } from "./InnerCard";

interface CancellationPolicyCardProps {
  workplaceLateCancellationPeriod?: number;
  workplaceLateCancellationFeeHours?: number;
  workplaceSentHomeChargeHours?: number;
  onViewWorkplacePolicies: () => void;
  onCancelShift: () => void;
  lastUpdatedAt?: Date;
}

export function CancellationPolicyCard(props: CancellationPolicyCardProps) {
  const {
    workplaceLateCancellationPeriod,
    workplaceLateCancellationFeeHours,
    workplaceSentHomeChargeHours,
    onViewWorkplacePolicies,
    onCancelShift,
    lastUpdatedAt,
  } = props;

  const hasLateCancellationPayout =
    isDefined(workplaceLateCancellationPeriod) &&
    isDefined(workplaceLateCancellationFeeHours) &&
    workplaceLateCancellationPeriod > 0 &&
    workplaceLateCancellationFeeHours > 0;

  const hasSentHomePayout =
    isDefined(workplaceSentHomeChargeHours) && workplaceSentHomeChargeHours > 0;

  if (!hasLateCancellationPayout && !hasSentHomePayout) {
    return null;
  }

  return (
    <ShiftDetailsInnerCard
      footer={
        <Stack spacing={4}>
          <Button fullWidth variant="outlined" size="medium" onClick={onViewWorkplacePolicies}>
            View workplace policies
          </Button>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            sx={{
              color: (theme) => theme.palette.error.main,
            }}
            onClick={onCancelShift}
          >
            Cancel my shift
          </Button>
        </Stack>
      }
    >
      <CardContent sx={{ paddingY: 2, paddingX: 2 }}>
        <Stack spacing={4} sx={{ paddingX: 4, paddingY: 4 }}>
          <Text semibold variant="h5">
            Cancellation Policies
          </Text>

          {hasLateCancellationPayout && (
            <Text variant="body2" sx={{ color: (theme) => theme.palette.text.primary }}>
              Shifts cancelled by this workplace within {workplaceLateCancellationPeriod} hours
              before shift start will lead to a {workplaceLateCancellationFeeHours} hour payout for
              the scheduled worker.
            </Text>
          )}
          {hasSentHomePayout && (
            <Text variant="body2" sx={{ color: (theme) => theme.palette.text.primary }}>
              Shifts cancelled after the scheduled worker has arrived at the workplace will lead to
              a {workplaceSentHomeChargeHours} hour payout.
            </Text>
          )}
          {isDefined(lastUpdatedAt) && (
            <Text variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
              As of {formatDate(lastUpdatedAt)}
            </Text>
          )}
        </Stack>
      </CardContent>
    </ShiftDetailsInnerCard>
  );
}
